import React from "react"
import { Link } from "gatsby"
import Header from "./header"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  @font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/playfair-display-v22-latin-500italic.eot');
  src: local(''),
  url('/fonts/playfair-display-v22-latin-500italic.eot?#iefix') format('embedded-opentype'),
  url('/fonts/playfair-display-v22-latin-500italic.woff2') format('woff2'),
  url('/fonts/playfair-display-v22-latin-500italic.woff') format('woff'),
  url('/fonts/playfair-display-v22-latin-500italic.ttf') format('truetype'),
  url('/fonts/playfair-display-v22-latin-500italic.svg#PlayfairDisplay') format('svg');
  }
  @font-face {
  font-family: 'Gilroy Extrabold';
  src: url("/fonts/gilroy-extrabold.eot");
  src: url("/fonts/gilroy-extrabold.woff2") format("woff2"),
  url("/fonts/gilroy-extrabold.woff") format("woff"),
  url("/fonts/gilroy-extrabold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  }

  .home-hdg {
    font-family: 'Playfair Display', Georgia, serif;
    font-style: italic;
    font-weight: 500;
    text-transform: uppercase;
  }
  h1, h2, h3, h4, h5 {
    font-family: 'Gilroy Extrabold', Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  .port-hdg {
    text-transform: uppercase;
  }
`;

const Layout = ({ isHomePage, children }) => {

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <GlobalStyle />
      <Header />

      <main className="main-content" role="main">
        {children}
      </main>

      <footer className="global-footer" role="contentinfo">
        <div className="max-inner">
          <span className="site-info copyright">&copy; {new Date().getFullYear()} Chad Rieder</span> | <Link to="/privacy-policy/">Privacy</Link>
        </div>
      </footer>
    </div>
  )
}

export default Layout
