import React, { Component } from "react"
import { Link } from "gatsby"
import Menu from "./menu"
import logo from "../images/chad-rieder-logo.svg"

class Header extends Component { 

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }
    
    handleScroll = () => {
        if(document.location.pathname === "/") {
            let scrollpos = window.scrollY
            const hero = document.querySelector('.home-intro');
            const hero_height = hero.offsetHeight
        
            if(scrollpos >= hero_height) { 
                document.querySelector(".global-header").className = "global-header scrolled";
                } else {
                    document.querySelector(".global-header").className = "global-header";
                }
            }
        else {
            //do nothing
        }
    }
       
    render() {
        return(
            
            <header className="global-header" role="banner">
                <div className="inner-header max-inner">
                    <div className="site-logo">
                    <Link to="/">
                        <img src={logo} alt="Chad Rieder logo" />
                    </Link>
                    </div>
                <Menu />
                </div>
            </header>
        )
    }

}
export default Header
