import React, { useState } from "react"
import { Link } from "gatsby"
import gsap from "gsap"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const Menu = () => {
  
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  
  function navLinks() {
    //GSAP animate links
    gsap.from(".nav-link", {
      duration: .2,
      opacity: 0,
      y: 60,
      stagger: 0.1,
      clearProps: true,
      delay: 0.2
    });
  }

  //Set body overflow hidden when menu open
  function noScroll() {
    const targetElement = document.querySelectorAll('.main-nav');
    const menuLink = document.querySelectorAll(".nav-link a");
    
    if(!isOpen) {
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
      clearAllBodyScrollLocks(targetElement);
    }

    //Remove body overflow hidden on route change
    for (let i = 0; i < menuLink.length; i++) {
      menuLink[i].addEventListener("click", function() {
        enableBodyScroll(targetElement);
        clearAllBodyScrollLocks(targetElement);
      });
    }
  }

  return (
    <div className="nav-wrap">
      <Link activeClassName="active" className="connect-ext" to="/connect/">Connect</Link>
      <button className={isOpen ? 'menu-btn open' : 'menu-btn '}
        onClick={() => {
          toggle();
          navLinks();
          noScroll();
        }}>
        menu
        <span></span>
        <span></span>
        <span></span>
      </button>
      <nav role="navigation" className={isOpen ? 'main-nav open' : 'main-nav '}>
        <div className="nav-inner">
          <div className="nav-list-wrap">
            <ul className="main-nav-list">
              <li className="nav-link music-link"><Link activeClassName="active" to="/music/">Music</Link></li>
              <li className="nav-link landscape-link"><Link activeClassName="active" to="/landscape/">Landscape</Link></li>
              <li className="nav-link event-link"><Link activeClassName="active" to="/event/">Event</Link></li>
              <li className="nav-link blog-link"><Link activeClassName="active" to="/perspective/" partiallyActive={true}>Perspective</Link></li>
            </ul>
            <ul className="secondary-nav-list">
              <li className="nav-link"><Link activeClassName="active" to="/about/">About</Link></li>
              <li className="nav-link"><Link activeClassName="active" to="/connect/">Connect</Link></li>
              <li className="nav-link"><a href="https://www.etsy.com/shop/chadrieder/" target="blank" className="external" rel="noreferrer">Buy Prints</a></li>
              <li className="nav-link"><a href="https://www.instagram.com/chadrieder/" target="blank" className="external" rel="noreferrer">Instagram</a></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default Menu
