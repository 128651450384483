import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const About = () => {
  return (
    <Layout>
      <Seo title="Chad Rieder | About" description="Learn about Minneapolis, MN based event, landscape, and music photographer Chad Rieder. Contact for work availability and photography licensing." />
        <div className="max-inner">
          <div className="max-copy pad-inner">
            <div>
              <p>
                I am a self-taught photographer who is driven by curiosity and a love for music and nature. My work has placed me in front of sold-out arenas photographing some of the largest artists in music, and on the steep edges of cliffs looking for that perfect landscape shot. With a detailed eye and an endless amount of patience for perfection, I strive to provide my clients the best images I possibly can.                  
              </p>
              <p>
                While my work focuses mostly on music and event photography in the Minneapolis, MN area, I also do portrait photography and weddings on occasion. I travel for the right projects and hope we can work together soon.
              </p>
              <p>Check out my interview with <a href="https://voyageminnesota.com/interview/daily-inspiration-meet-chad-rieder/" target="_blank" rel="noreferrer">Voyage Minnesota</a> and the kind words from <a href="https://www.peerspace.com/resources/landscape-photographers-minneapolis/" target="_blank" rel="noreferrer">Peerspace</a> about my photography.
              </p>
              <p>
                <strong>Equipment:</strong> Nikon cameras + lenses, Lee filters, Formatt-Hitech filters, Manfrotto tripod
              </p>
            </div>
            <div className="clients">
              <h2 className="hdg-2 clients-hdg">Clients:</h2>
              <ul className="client-list">
                <li>Warner Bros Records</li>
                <li>Elektra Records</li>
                <li>Jameson Whisky</li>
                <li>Hyundai</li>
                <li>Target</li>
                <li>Acoustic Guitar Magazine</li>
                <li>NYLON Magazine</li>
                <li>Mystic Lake Casino</li>
                <li>Minnesota Historical Society</li>
              </ul>
            </div>

            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1657718507/home/chad-rieder-minneapolis-music-photographer.jpg"
              alt="Chad Rieder Minneapolis Music Photographer"
              loading="eager"
              layout="fullWidth"
              className="bio-img"
            />
          </div>
        </div>
    </Layout>
  )
}

export default About
